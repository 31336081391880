import ReactDOM from "react-dom/client";
import {BrowserRouter, Routes, Route, Link} from "react-router-dom";

import {NetworkMembers} from "./components/members";
import {NetworkList} from "./components/networks";
import {MyClients} from "./components/clients";

function App() {

    return (
        <BrowserRouter>
            <div className={"pure-g"}>
                <div className={"pure-u-1-5"}>
                    <nav>
                        <li><Link to={"/networks"}>我的网络</Link></li>
                        <li><Link to={"/clients"}>我的设备</Link></li>
                    </nav>
                </div>

                <div className={"pure-u-4-5"}>

                    <Routes>
                        <Route path={"/networks"} element={<NetworkList/>}/>
                        <Route path={"/networks/:id"} element={<NetworkMembers/>}/>

                        <Route path={"/clients"} element={<MyClients/>}/>
                    </Routes>

                </div>

            </div>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById("app"));

root.render(<App/>);