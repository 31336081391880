import {useEffect, useState} from "react";
import {baseUrl, getToken, loginUrl} from "../utils";
import {ClientReply, Clients} from "./members";

export function MyClients() {
    const token = getToken();

    const [clients, setClients] = useState<Clients[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                let resp = await fetch(`${baseUrl}/clients`, {
                    headers: {
                        "authorization": `Bearer ${token}`
                    }
                });

                setLoading(false);

                if (resp.status === 401) {
                    window.location.replace(loginUrl);
                } else {
                    let body: ClientReply = await resp.json();
                    console.log(body);

                    if (!body.ok) {
                        alert("失败");
                    } else {
                        setClients(body.clients);
                    }
                }
            } catch (e) {
                alert("网络错误");
                console.error(e);
            }
        })();
    }, []);

    return (
        <div>
            {loading ? "loading" : <ul>
                {clients.map(it => <li>{it.client_id}</li>)}
            </ul>}
        </div>
    )
}