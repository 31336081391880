import {Link} from "react-router-dom";
import {getToken, baseUrl, loginUrl} from "../utils";
import {useState, useEffect} from "react";

export function NetworkList() {
    const token = getToken();

    const [networks, setNetworks] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                let resp = await fetch(`${baseUrl}/networks`, {
                    headers: {
                        "authorization": `Bearer ${token}`
                    }
                });

                if (resp.status === 401) {
                    window.location.replace(loginUrl);
                } else {
                    let body = await resp.json();
                    console.log(body);

                    if (!body.ok) {
                        alert("失败");
                    } else {
                        setNetworks(body["networks"]);
                        setLoading(false);
                    }
                }
            } catch (e) {
                alert("网络错误");
                console.error(e);
            }
        })();
    }, []);

    return (
        <div>
            {loading ? "loading" : <ul>
                {networks.map(it => (
                    <li key={it.id}><Link to={`/networks/${it["network_id"]}`}>{it["network_id"]}</Link></li>
                ))}
            </ul>}
        </div>
    );
}