import {useEffect, useState} from "react";
import {baseUrl, getToken, loginUrl} from "../utils";

interface PathProps {
    id: String,

    members: String[],
}

interface Reply {
    ok: boolean,
    paths: PathObj[]
}

interface PathEntry {
    id: String,
    orb: number,
    priority: number,
}

type Flow = "Orb" | "Direct" | "BlackHole";

interface PathObj {
    flow: Flow,
    id: number,
    path: PathEntry[]
}

interface AddPathReply {
    ok: boolean,
    id: number,
}

export function NetworkPaths(props: PathProps) {
    const networkId = props.id;
    const members = props.members;
    const token = getToken();

    const [paths, setPaths] = useState<PathObj[]>([]);
    const [clientA, setA] = useState(members[0] || "");
    const [clientB, setB] = useState(members[0] || "");
    const [orbA, setOrbA] = useState(0);
    const [orbB, setOrbB] = useState(0);
    const [priority, setPriority] = useState(10);

    const [loading, setLoading] = useState(true);

    const [type, setType] = useState<Flow>("Orb");

    useEffect(() => {
        (async () => {
            try {
                let resp = await fetch(`${baseUrl}/networks/${networkId}/paths`, {
                    headers: {
                        "authorization": `Bearer ${token}`
                    }
                });

                if (resp.status === 401) {
                    window.location.replace(loginUrl);
                } else {
                    let body: Reply = await resp.json();
                    console.log(body);

                    if (!body.ok) {
                        alert("失败");
                    } else {
                        setPaths(body.paths);
                        setLoading(false);
                    }
                }
            } catch (e) {
                alert("网络错误");
                console.error(e);
            }
        })();
    }, []);

    useEffect(() => {
        setA(members[0] || "");
        setB(members[0] || "");
    }, [members]);

    const addPath = async () => {
        console.log(`clientA: ${clientA}, clientB: ${clientB}`);

        if (clientA === clientB) {
            alert("ID 不能相同");
            return;
        }

        if (type === "Orb" && (orbA === 0 || orbB === 0)) {
            alert("Orb 不能为 0");
            return;
        }

        const body = {
            flow: type,
            path: [
                {
                    id: clientA,
                    orb: orbA,
                    priority: priority
                },
                {
                    id: clientB,
                    orb: orbB,
                    priority: priority
                }
            ]
        };

        try {
            setLoading(true);
            let resp = await fetch(`${baseUrl}/networks/${networkId}/paths`, {
                method: "post",
                headers: {
                    "authorization": `Bearer ${token}`,
                    "content-type": "application/json"
                },
                body: JSON.stringify(body),
            });

            if (resp.status === 401) {
                window.location.replace(loginUrl);
            } else {
                let rep: AddPathReply = await resp.json();
                if (rep.ok) {
                    let newObj = {
                        id: rep.id,
                        flow: type,
                        path: [
                            {
                                id: clientA,
                                orb: orbA,
                                priority: priorityA
                            },
                            {
                                id: clientB,
                                orb: orbB,
                                priority: priorityB
                            }
                        ]
                    };

                    setPaths([...paths, newObj]);
                    setLoading(false);
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    const removePath = async (id: number) => {
        try {
            setLoading(true);
            let resp = await fetch(`${baseUrl}/networks/${networkId}/paths/${id}`, {
                method: "delete",
                headers: {
                    "authorization": `Bearer ${token}`
                }
            });

            if (resp.status === 401) {
                window.location.replace(loginUrl);
            } else {
                setPaths(paths.filter(it => it.id !== id));
                setLoading(false);
            }

        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div>
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Client A</th>
                    <th>Orb</th>
                    <th>Client B</th>
                    <th>Orb</th>
                    <th>Type</th>
                    <th>Priority</th>
                    <th>操作</th>
                </tr>
                </thead>

                <tbody>
                {paths.map(it =>
                    <tr key={it.id}>
                        <td>{it.path[0].id}</td>
                        <td>{it.path[0].orb}</td>
                        <td>{it.path[1].id}</td>
                        <td>{it.path[1].orb}</td>
                        <td>{it.flow}</td>
                        <td>{it.path[1].priority}</td>
                        <td>
                            <button className={`button is-danger ${loading ? "is-loading" : ""}`}
                                    onClick={() => removePath(it.id)}>删除
                            </button>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>

            <div className={"block"}>
                <h3>添加</h3>

                <div className={"block"}>
                    <label>Type</label>
                    <select style={{marginLeft: "1vh"}} onChange={event => setType(event.target.value as Flow)}>
                        <option value={"Orb"}>Orb</option>
                        <option value={"Direct"}>Direct</option>
                        <option value={"BlackHole"}>BlackHole</option>
                    </select>

                    <label style={{marginLeft: "1vh"}}>Priority</label>
                    <input style={{marginLeft: "1vh"}} type={"number"} value={priority}
                           onChange={(e) => setPriority(parseInt(e.target.value))}/>
                </div>

                <div className={"block"}>
                    <label style={{marginLeft: "1vh"}}>A</label>
                    <select style={{marginLeft: "1vh"}} value={clientA} onChange={(e) => setA(e.target.value)}>
                        {members.map(it => <option value={it} key={it}>{it}</option>)}
                    </select>

                    <label style={{marginLeft: "1vh"}}>Orb</label>
                    <input style={{marginLeft: "1vh"}} type={"number"} value={orbA}
                           onChange={(e) => setOrbA(parseInt(e.target.value))}/>
                </div>

                <div className={"block"}>
                    <label style={{marginLeft: "1vh"}}>B</label>
                    <select style={{marginLeft: "1vh"}} value={clientB} onChange={(e) => setB(e.target.value)}>
                        {members.map(it => <option value={it} key={it}>{it}</option>)}
                    </select>

                    <label style={{marginLeft: "1vh"}}>Orb</label>
                    <input style={{marginLeft: "1vh"}} type={"number"} value={orbB}
                           onChange={(e) => setOrbB(parseInt(e.target.value))}/>
                </div>

                <button style={{marginLeft: "1vh"}}
                        className={`button is-primary is-small ${loading ? "is-loading" : ""}`}
                        onClick={() => addPath()}>添加
                </button>
            </div>
        </div>
    )
}