import {useParams} from "react-router-dom";
import {baseUrl, getToken, loginUrl} from "../utils";
import {useEffect, useState} from "react";
import {NetworkPaths} from "./network-paths";

export interface Clients {
    id: number,

    client_id: String,

    public_key: String,
}

export interface ClientReply {
    ok: boolean,
    clients: Clients[],
}

export function NetworkMembers() {
    const param = useParams();
    const token = getToken();

    const networkId = param.id;

    const [members, setMembers] = useState<Clients[]>([]);
    const [loading, setLoading] = useState(true);

    const [clients, setClients] = useState<Clients[]>([]);

    const [addClient, setAdd] = useState("");

    const loadMember = async (networkId) => {
        try {
            let resp = await fetch(`${baseUrl}/networks/${networkId}/members`, {
                headers: {
                    "authorization": `Bearer ${token}`
                }
            });

            setLoading(false);

            if (resp.status === 401) {
                window.location.replace(loginUrl);
            } else {
                let body = await resp.json();
                console.log(body);

                if (!body.ok) {
                    alert("失败");
                } else {
                    setMembers(body["clients"]);
                }
            }
        } catch (e) {
            alert("网络错误");
            console.error(e);
        }
    };

    useEffect(() => {
        loadMember(networkId);
    }, []);

    useEffect(() => {
        (async () => {
            try {
                let resp = await fetch(`${baseUrl}/clients`, {
                    headers: {
                        "authorization": `Bearer ${token}`
                    }
                });

                if (resp.status === 401) {
                    window.location.replace(loginUrl);
                } else {
                    let body: ClientReply = await resp.json();
                    console.log(body);

                    if (!body.ok) {
                        alert("失败");
                    } else {
                        setClients(body.clients);
                        setLoading(false);
                    }
                }
            } catch (e) {
                alert("网络错误");
                console.error(e);
            }
        })();
    }, []);

    useEffect(() => {
        setAdd(clients[0]?.client_id || "");
    }, [clients]);

    const removeMember = async (clientId) => {
        console.warn(`remove client ${clientId} from network ${networkId}`);

        try {
            setLoading(true);
            let resp = await fetch(`${baseUrl}/networks/${networkId}/members/${clientId}`, {
                method: "delete",
                headers: {
                    "authorization": `Bearer ${token}`
                }
            });

            if (resp.status === 401) {
                window.location.replace(loginUrl);
            } else {
                let body = await resp.json();
                console.log(body);

                if (!body.ok) {
                    alert("失败");
                } else {
                    setMembers(members.filter((it, idx) => it.client_id !== clientId));
                    setLoading(false);
                }
            }
        } catch (e) {
            alert("网络错误");
            console.error(e);
        }
    };

    const addMember = async () => {
        try {
            console.log(`add ${addClient}`);
            console.log(members.find(it => it.client_id === addClient));
            if (members.find(it => it.client_id === addClient) !== undefined) {
                alert("已经存在");
                return;
            }

            setLoading(true);
            let resp = await fetch(`${baseUrl}/networks/${networkId}/members`, {
                method: "post",
                headers: {
                    "authorization": `Bearer ${token}`,
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    client_id: addClient,
                })
            });

            setLoading(false);

            if (resp.status === 401) {
                window.location.replace(loginUrl);
            } else {
                let body = await resp.json();
                console.log(body);

                if (!body.ok) {
                    alert("失败");
                } else {
                    loadMember(networkId);
                }
            }
        } catch (e) {
            alert(e);
            console.error(e);
        }
    };

    return (
        <div className={"content"}>
            <h2>{param.id}</h2>

            <div className={"block"}>
                <span>成员</span>
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>公钥</th>
                        <th>操作</th>
                    </tr>
                    </thead>

                    <tbody>
                    {members.map(it =>
                        <tr key={it.id}>
                            <td>{it.client_id}</td>
                            <td>{it.public_key}</td>
                            <td>
                                <button className={`button is-danger ${loading ? "is-loading" : ""}`}
                                        onClick={() => removeMember(it.client_id)}>删除
                                </button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>

                <div>
                    <h3>添加客户端</h3>
                    <select value={addClient} onChange={(e) => setAdd(e.target.value)}>
                        {clients.map(it => <option value={it.client_id} key={it.id}>{it.client_id}</option>)}
                    </select>

                    <button className={`button is-primary is-small ${loading ? "is-loading" : ""}`}
                            onClick={() => addMember()} style={{marginLeft: "1vh"}}>
                        添加
                    </button>
                </div>
            </div>

            <div className={"block"}>
                <span>网络路径</span>
                <NetworkPaths id={networkId} members={members.map(it => it.client_id)}/>
            </div>
        </div>
    );
}